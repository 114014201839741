// 
// avatar
// 

.avatar-xs {
    height: 1rem;
    width: 1rem;
  }
  
  .avatar-sm {
    height: 3rem;
    width: 3rem;
  }
  
  .avatar-md {
    height: 4rem;
    width: 4rem;
  }
  
  .avatar-lg {
    height: 6rem;
    width: 6rem;
  }
  
  .avatar-xl {
    height: 7.5rem;
    width: 7.5rem;
  }
  
  .avatar-title {
    align-items: center;
    background-color: $primary;
    color: $white;
    display: flex;
    font-weight: $font-weight-medium;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
  
  
  // avatar group
  .avatar-group {
    padding-left: 12px;
    display: flex;
    flex-wrap: wrap;
    .avatar-group-item {
      margin-left: -12px;
      border: 2px solid $gray-100;
      border-radius: 50%;
      transition: all 0.2s;
      &:hover{
        position: relative;
        transform: translateY(-2px);
      }
    }
  }