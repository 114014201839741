
// 
//  client
// 

/*------ BOOK CLIENT ------*/

.book-client-desc p {
    border: 1px solid $gray-200;
}

#book-owl-demo .owl-pagination {
    display: none;
}

.book-client-desc:after {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-color: $white;
    border: 1px solid $gray-200;
    transform: rotate(45deg);
    border-color: transparent $gray-200 $gray-200 transparent;
    left: 0;
    right: 0;
    margin: 0px auto;
    margin-top: -30px;
}

.book-client-img img {
    width: 80px;
}


/*------ BUSINESS TESTIMONIAL ------*/

.business-client {
    .business-carousel {
        .carousel-inner {
            min-height: 400px;
        }
        .carousel-indicators {
            align-items: center;
            .active {
                background-color: none;
                opacity: 1;
            }
            button {
                cursor: pointer;
                width: 64px !important;
                height: 64px !important;
                margin-right: 5px;
                margin-left: 5px;
                opacity: 0.4;
                border-radius: 50%;
                border: 3px solid $white;
                overflow: hidden;
                transition: all 0.5s;
            }
        }
        .business-testi-box {
            max-width: 800px;
            margin: 0 auto;
            padding: 30px 30px;
            border: 1px solid $gray-200;
            &:after {
                content: "";
                position: absolute;
                width: 30px;
                height: 30px;
                background-color: $white;
                transform: rotate(45deg);
                border: 1px solid $warning;
                border-color: transparent $gray-200 $gray-200 transparent;
                left: 0;
                right: 0;
                margin: 0px auto;
                margin-top: 15px;
            }
        }
        .carousel-control-next-icon,
        .carousel-control-prev-icon {
            display: inline-block;
            width: 30px;
            height: 30px;
            background-color: $primary;
            background-size: 100% 50%;
            border-radius: 3px;
        }
    }
}

/*------ CONSTRUCTION-CLIENT ------*/

.construction-client-box {
    border: 1px solid $gray-200;
    border-radius: 6px;
    .construction-client-img {
        img {
            border: 1px solid $gray-200;
            position: absolute;
            top: 0px;
            max-width: 100px;
            background-color: $white;
            padding: 8px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }
}

#construction-owl-demo .owl-pagination {
    display: none;
}

// gym-client

.gym-client {
    .carousel-indicators {
        bottom: -42px;
    }
    .gym-client-img {
        width: 100px;
        border-radius: 3px;
    }
}

/*------ INTERIOR-CLIENT ------*/

.interior-client {
    .swiper {
        padding-bottom: 60px;
        .interior-client-box {
            border: 1px solid $gray-200;
            .interior-client-img {
                img {
                    width: 90px;
                }
            }
            .interior-client-content {
                overflow: hidden;
            }
        }
        .swiper-pagination-bullet {
            border: 2px solid $primary;
            background-color: transparent;
            height: 15px;
            width: 15px;
            background-color: $primary;
        }
    }
}

/*------ PHOTOGRAPHER CLIENT ------*/

.fonbeat-client-icon {
    i {
        font-size: 40px;
    }
}
.startup-client-img {
    img {
        width: 80px;
    }
}





