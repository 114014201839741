/* Video Gallery Layout */
.video-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin: 20px 0;
}

/* Modal Styles */
.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  width: 90%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

/* Close Button */
.close-button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #000;
  color: #fff;
  border: none;
  font-size: 16px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  font-weight: bold;
}

.close-button:hover {
  background: #232323;
}

/* Video Iframe */
.video-iframe {
  width: 100%;
  height: 450px;
  border: none;
  border-radius: 10px;
}

/* Estiliza cada vídeo */
.video-item {
  position: relative;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  /* Garante que o overlay fique contido no vídeo */
  border-radius: 10px;
  transition: transform 0.3s ease;
  /* Suaviza o zoom no hover */
}

/* Miniatura */
.video-thumbnail {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  /* Mesmo border-radius do item */
  display: block;
  transition: transform 0.3s ease;
  /* Suaviza o zoom no hover */
}

/* Overlay Permanente */
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Fundo semi-transparente */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  /* Mesmo border-radius da miniatura */
  pointer-events: none;
  /* Garante que o hover funcione no item */
  transition: transform 0.3s ease;
  /* Suaviza o zoom no hover */
}

/* Ícone de play centralizado */
.video-overlay i {
  color: #fff;
  /* Ícone branco */
  font-size: 100px;
  /* Tamanho do ícone */
  transition: transform 0.3s ease;
  /* Suaviza o zoom no hover */
}

/* Título do vídeo */
.video-title {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

/* Efeito de Zoom ao passar o mouse */
.video-item:hover {
  transform: scale(1.05);
  /* Aumenta o tamanho do item */
}

.video-item:hover .video-thumbnail {
  transform: scale(1.2);
  /* Aumenta o zoom da miniatura */
}

.video-item:hover .video-overlay i {
  transform: scale(1.2);
  /* Aumenta o ícone no hover */
}