//  form 

/*------ GYM-FORM ------*/

.bg-gym-form {
    background: url(../images/background/bg-6.jpg);
    background-size: cover;
    background-position: center center;
    position: relative;
}

.gym-form label {
    color: $gray-300;
    margin-bottom: 20px;
}

.gym-form .form-control {
    height: 48px;
    background-color: rgba(255, 255, 255, 0.4);
    border: transparent;
    color: $white;
}