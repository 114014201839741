// 
//  about - agency
// 

.about {
    .progress {
        height: 3px;
        background: rgba($muted, 0.1);
        overflow: visible;
        .progress-bar {
            position: relative;
            background-color: rgba($primary, 1);
            border-radius: 0px;
            animation: animate-positive 2s;
            overflow: visible;
            .progress-value {
                display: block;
                color: rgba($primary, 1);
                position: absolute;
                top: -28px;
                right: -25px;
                font-size: 13px;
            }
        }
    }
}


@keyframes animate-positive {
    0% {
        width: 0;
    }
}

/*------ APP-ABOUT------*/

.app-about {
    .app-about-icon  {
        i {
            font-size: 35px;
        }
    }
}


/*------ BOOK ABOUT ------*/


.book-about {
    .book-about-icon {
        i {
            font-size: 35px;
        }
    }
    .book-about-border {
        width: 40px;
        height: 2px;
        background-color: rgba($primary, 1);
        opacity: 0.4;
    }
    .book-about-title {
        font-size: 26px;
        text-transform: uppercase;
    }
    .book-baout-signicher {
        font-size: 20px;
        font-family: 'Dancing Script', cursive;
    }
}


/*------ BUSINESS ABOUT ------*/

.business-about {
    .business-about-img {
        position: relative;
        img {
            border-radius: 30px;
        }
        .bg-color-overlay {
            background-color: $gray-dark-100;
            opacity: 0.5;
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 100%;
            border-radius: 30px;
        }
        .modal-btn {
            position: absolute;
            bottom: 50%;
            transform: translateY(50%);
            right: 0;
            left: 0;
            text-align: center;
            .play-btn {
                height: 90px;
                width: 90px;
                font-size: 30px;
                line-height: 90px;
                border-radius: 50%;
                display: inline-block;
                background-color: $white;
                color: rgba($primary, 1);
                .btn-icon {
                    i {
                        font-size: 40px;
                    }
                }
            }
        }
        .home-modal {
            border: 0;
            background-color: transparent;
        }
        .btn-close {
            width: 24px;
            height: 24px;
            background-color: rgba(26,51,101,.5);
            border-radius: 4px;
            position: relative;
            top: 60px;
            right: 3px;
            z-index: 3;
        }
    }
    .business-about-content {
        .business-about-features {
            span {
                color: rgba($muted, 1);
            }
            .about-icon {
                height: 22px;
                width: 22px;
                display: inline-block;
                text-align: center;
                line-height: 22px;
                font-size: 12px;
                border-radius: 50%;
                background-color: rgba($primary, 1);
                color: $white;
            }
            .business-about-highlight {
                text-decoration: underline !important;
                color: rgba($primary, 1)!important;
                font-weight: 600;
            }
        }
    }
}


/*------ CONSTRUCTION-ABOUT ------*/

.construction-about {
    padding: 80px 0px 120px 0px;
    .construction-about-image {
        position: relative;
        display: inline-block;
        &:before {
            border: 8px solid $primary;
            width: 100%;
            height: 100%;
            content: '';
            top: 35px;
            right: -35px;
            position: absolute;
        }
    }
    .construction-about-icon  {
        i {
            font-size: 30px;
        }
    }
}

    /*------ INTERIOR-ABOUT ------*/
.about {
    .interior-about-img {
        box-shadow: 30px 30px 0px 0px rgba($muted, 0.1);
        margin-bottom: 30px;
    }
}

/*------ fonbeat-ABOUT ------*/

.fonbeat-about-title {
    font-size: 22px;
}

.fonbeat-about-border {
    width: 60px;
    height: 2px;
    background-color: $primary;
}


/*------ fonbeat-about ------*/


.progress {
    height: 3px;
    background: rgba($muted, 0.1);
    overflow: visible;
    .progress-bar {
        position: relative;
        background-color: rgba($primary, 1);
        border-radius: 0px;
        animation: animate-positive 2s;
        overflow: visible;
        .progress-value {
            display: block;
            color: rgba($primary, 1);
            position: absolute;
            top: -28px;
            right: -25px;
            font-size: 13px;
        }
    }
}

/*------ SOFTWERE-ABOUT ------*/

.software-about {
    .softwere-about-icon {
        i {
            font-size: 32px;
            border: 1px solid $gray-200;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            line-height: 80px;
            color: $primary;
            &:hover {
                border: 1px solid $primary;
                background-color: $primary;
                transition: all 0.5s;
                color: $white;
            }
        }
    }
}

/*------ STARTUP ABOUT ------*/

.startup-about {
    .startup-about-heading {
        font-size: 17px;
        max-width: 800px;
        margin: 0 auto;
    }
    .startup-about-icon {
        i {
            font-size: 30px;
        }
    }
    .startup-about-content {
        overflow: hidden;
    }
}